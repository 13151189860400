<template>
  <div class="pa-1">

    <v-progress-linear
      indeterminate
      color="primary"
      v-if="loading"
    ></v-progress-linear>
    <div v-else>
      <h5 class="ml-4">Influencers selected by You</h5>
      <div v-if="platform === 1">
        <v-data-table
          v-if="this.selectedInfluencers.length > 0"
          :headers="headersyoutube"
          :items-per-page="this.selectedInfluencers.length"
          :disable-sort="true"
          :items="indexedItems"
          mobile-breakpoint="0"
          hide-default-footer
        >
          <template v-slot:[`item.sr`]="{ item }">{{item.sr}}</template>
          <template v-slot:[`item.avatar`]="{ item }">
            <a
              :href="item.channel_link"
              target="_blank"
              class="d-flex align-center"
            >
              <div>
                <v-img
                  v-if="item.avatar"
                  :src="item.avatar"
                  class="img"
                ></v-img>
                <v-img
                  v-else
                  class="img"
                  :src="`https://ui-avatars.com/api/?name=${item.channel_name}&background=F54153&color=FFF`"
                ></v-img>
              </div>
              <span class="category">
                <strong>{{ item.channel_name }}</strong> <br>
                <v-chip
                  v-if="item.category"
                  x-small
                  color="teal"
                  text-color="white"
                >
                  {{ item.category }}
                </v-chip>
              </span>
            </a>
          </template>
          <template v-slot:[`item.yt_category`]="{ item }">
            {{item.yt_category ? catName(item.yt_category) : '-'}}
          </template>
          <template v-slot:[`item.language`]="{ item }">
            {{item.language ? item.language : '-'}}
          </template>
          <template v-slot:[`item.gender`]="{ item }">
            {{item.gender ? item.gender : '-'}}
          </template>
          <template v-slot:[`item.eng_rate`]="{ item }">
            {{ item.eng_rate ? item.eng_rate + '%' : '-'}}
          </template>
          <template v-slot:[`item.total_video`]="{ item }">
            {{item.total_video | formatNumber}}
          </template>
          <template v-slot:[`item.subscribers`]="{ item }">
            {{item.subscribers | formatNumber}}
          </template>
          <template v-slot:[`item.total_view`]="{ item }">
            {{item.total_view | formatNumber}}
          </template>
          <template v-slot:[`item.latest_avg_view`]="{ item }">
            {{item.latest_avg_view | formatNumber}}
          </template>
          <template v-slot:[`item.add_time`]="{ item }">
            {{item.add_time | dateFormat}}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              v-if="item.status === 0"
              color="warning"
              small
              outlined
            >
              <v-icon
                small
                left
              >mdi-alert-circle-outline</v-icon> Pending
            </v-chip>
            <v-chip
              v-if="item.status === 1"
              color="success"
              small
              outlined
            >
              <v-icon
                small
                left
              >mdi-check-circle-outline</v-icon> Approved
            </v-chip>
            <v-chip
              v-if="item.status === 2"
              color="error"
              small
              outlined
            >
              <v-icon
                small
                left
              >mdi-close-octagon-outline</v-icon> Rejected
            </v-chip>
          </template>
          <template v-slot:[`item.last_video_date`]="{ item }">
            {{item.last_video_date | dateFormat }}
          </template>
          <template v-slot:[`item.reason`]="{ item }">
            <div
              v-if="item.status===0"
              style="color:#a55b27"
            >Admin Approval Pending</div>
            <div
              v-else
              style="color:#a55b27"
            >{{item.client_remark}}</div>
          </template>
          <template v-slot:[`item.id`]="{ item }">
            <div
              class="d-flex align-center justify-center"
              v-if="item.status===0"
            >
              <v-btn
                icon
                dark
                color="secondary"
                size="28"
                @click="assignedDelete(item)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </div>
      <div v-else>
        <v-data-table
          v-if="this.selectedInfluencers.length > 0"
          :headers="headersinsta"
          :disable-sort="true"
          :items="indexedItems"
          :items-per-page="this.selectedInfluencers.length"
          mobile-breakpoint="0"
          hide-default-footer
        >
          <template v-slot:[`item.sr`]="{ item }">{{item.sr}}</template>
          <template v-slot:[`item.avatar`]="{ item }">
            <a
              :href="item.insta_link"
              target="_blank"
              class="d-flex align-center"
            >
              <div>
                <v-badge
                  v-if="item.is_verify"
                  color="success"
                  icon="mdi-check"
                  avatar
                  bordered
                  overlap
                >
                  <v-avatar size="45">
                    <v-img
                      class="img"
                      :src="`https://ui-avatars.com/api/?name=${item.insta_name}&background=F54153&color=FFF`"
                    ></v-img>
                  </v-avatar>
                </v-badge>
                <v-img
                  v-else
                  class="img"
                  :src="`https://ui-avatars.com/api/?name=${item.insta_name}&background=F54153&color=FFF`"
                ></v-img>
              </div>
              <span class="category">
                <strong>{{ item.insta_name }}</strong> <br>
                <v-chip
                  v-if="item.category"
                  x-small
                  color="teal"
                  text-color="white"
                >
                  {{ item.category }}
                </v-chip>
              </span>
            </a>
          </template>
          <template v-slot:[`item.insta_name`]="{ item }">
            <span>
              {{item.insta_name ? item.insta_name : '-'}} </span>
            <v-chip
              v-if="item.category"
              x-small
              color="teal"
              text-color="white"
            >
              {{ item.category }}
            </v-chip>
          </template>
          <template v-slot:[`item.category`]="{ item }">
            {{item.category ? item.category : '-'}}
          </template>
          <template v-slot:[`item.language`]="{ item }">
            {{item.language ? item.language : '-'}}
          </template>
          <template v-slot:[`item.gender`]="{ item }">
            {{item.gender ? item.gender : '-'}}
          </template>
          <template v-slot:[`item.total_media`]="{ item }">
            {{item.total_media | formatNumber}}
          </template>
          <template v-slot:[`item.followers`]="{ item }">
            {{item.followers | formatNumber}}
          </template>
          <template v-slot:[`item.add_time`]="{ item }">
            {{item.add_time | dateFormat}}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              v-if="item.status === 0"
              color="warning"
              small
              outlined
            >
              <v-icon
                small
                left
              >mdi-alert-circle-outline</v-icon> Pending
            </v-chip>
            <v-chip
              v-if="item.status === 1"
              color="success"
              small
              outlined
            >
              <v-icon
                small
                left
              >mdi-check-circle-outline</v-icon> Approved
            </v-chip>
            <v-chip
              v-if="item.status === 2"
              color="error"
              small
              outlined
            >
              <v-icon
                small
                left
              >mdi-close-octagon-outline</v-icon> Rejected
            </v-chip>
          </template>
          <template v-slot:[`item.reason`]="{ item }">
            <div
              v-if="item.status===0"
              style="color:#a55b27"
            >Admin Approval Pending</div>
            <div
              v-else
              style="color:#a55b27"
            >{{item.client_remark}}</div>
          </template>
          <template v-slot:[`item.id`]="{ item }">
            <div
              class="d-flex align-center justify-center"
              v-if="item.status === 0"
            >
              <v-btn
                icon
                dark
                color="secondary"
                size="28"
                @click="assignedDelete(item)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </div>
      <div
        class="text-center ma-8"
        v-if="!loading && selectedInfluencers.length <= 0"
      >
        <img
          src="@/assets/images/no-campaign.png"
          width="150"
        />
        <h3>No Influencers Found</h3>
        <v-btn
          depressed
          dark
          large
          color="primary"
          to="/influencer/list"
          style="width: 350px;"
        >Select and add influencers</v-btn>
      </div>
      <h5
        v-if="!loading && this.influencersAppliedToCampaign.length > 0"
        class="ml-4 mt-6"
      >Applied Influencers (YouTuber & Instagrammer)</h5>
      <div v-if="platform === 1">
        <v-data-table
          v-if="this.influencersAppliedToCampaign.length"
          :headers="headersyoutube"
          :items-per-page="this.influencersAppliedToCampaign.length"
          :disable-sort="true"
          :items="indexedItems2"
          mobile-breakpoint="0"
          hide-default-footer
        >
          <template v-slot:[`item.sr`]="{ item }">{{item.sr}}</template>
          <template v-slot:[`item.avatar`]="{ item }">
            <a
              :href="item.channel_link"
              target="_blank"
              class="d-flex align-center"
            >
              <div>
                <v-img
                  v-if="item.avatar"
                  :src="item.avatar"
                  class="img"
                ></v-img>
                <v-img
                  v-else
                  class="img"
                  :src="`https://ui-avatars.com/api/?name=${item.channel_name}&background=F54153&color=FFF`"
                ></v-img>
              </div>
              <span class="category">
                <strong>{{ item.channel_name }}</strong> <br>
                <v-chip
                  v-if="item.category"
                  x-small
                  color="teal"
                  text-color="white"
                >
                  {{ item.category }}
                </v-chip>
              </span>
            </a>
          </template>
          <template v-slot:[`item.yt_category`]="{ item }">
            {{item.yt_category ? catName(item.yt_category) : '-'}}
          </template>
          <template v-slot:[`item.language`]="{ item }">
            {{item.language ? item.language : '-'}}
          </template>
          <template v-slot:[`item.gender`]="{ item }">
            {{item.gender ? item.gender : '-'}}
          </template>
          <template v-slot:[`item.eng_rate`]="{ item }">
            {{ item.eng_rate ? item.eng_rate + '%' : '-'}}
          </template>
          <template v-slot:[`item.total_video`]="{ item }">
            {{item.total_video | formatNumber}}
          </template>
          <template v-slot:[`item.subscribers`]="{ item }">
            {{item.subscribers | formatNumber}}
          </template>
          <template v-slot:[`item.total_view`]="{ item }">
            {{item.total_view | formatNumber}}
          </template>
          <template v-slot:[`item.latest_avg_view`]="{ item }">
            {{item.latest_avg_view | formatNumber}}
          </template>
          <template v-slot:[`item.add_time`]="{ item }">
            {{item.add_time | dateFormat}}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              v-if="item.status === 0"
              color="warning"
              small
              outlined
            >
              <v-icon
                small
                left
              >mdi-alert-circle-outline</v-icon> Pending
            </v-chip>
            <v-chip
              v-if="item.status === 1"
              color="success"
              small
              outlined
            >
              <v-icon
                small
                left
              >mdi-check-circle-outline</v-icon> Approved
            </v-chip>
            <v-chip
              v-if="item.status === 2"
              color="error"
              small
              outlined
            >
              <v-icon
                small
                left
              >mdi-close-octagon-outline</v-icon> Rejected
            </v-chip>
          </template>
          <template v-slot:[`item.last_video_date`]="{ item }">
            {{item.last_video_date | dateFormat }}
          </template>
          <template v-slot:[`item.reason`]="{ item }">
            <div
              v-if="item.status===0"
              style="color:#a55b27"
            >Admin Approval Pending</div>
            <div
              v-else
              style="color:#a55b27"
            >{{item.client_remark}}</div>
          </template>
          <template v-slot:[`item.id`]="{ item }">
            <div
              class="d-flex align-center justify-center"
              v-if="item.status===0"
            >
            </div>
          </template>
        </v-data-table>
      </div>
      <div v-else>
        <v-data-table
          v-if="this.influencersAppliedToCampaign.length"
          :headers="headersinsta"
          :items-per-page="this.influencersAppliedToCampaign.length"
          :disable-sort="true"
          :items="indexedItems2"
          mobile-breakpoint="0"
          hide-default-footer
        >
          <template v-slot:[`item.sr`]="{ item }">{{item.sr}}</template>
          <template v-slot:[`item.avatar`]="{ item }">
            <a
              :href="item.insta_link"
              target="_blank"
              class="d-flex align-center"
            >
              <div>
                <v-badge
                  v-if="item.is_verify"
                  color="success"
                  icon="mdi-check"
                  avatar
                  bordered
                  overlap
                >
                  <v-avatar size="45">
                    <v-img
                      class="img"
                      :src="`https://ui-avatars.com/api/?name=${item.insta_name}&background=F54153&color=FFF`"
                    ></v-img>
                  </v-avatar>
                </v-badge>
                <v-img
                  v-else
                  class="img"
                  :src="`https://ui-avatars.com/api/?name=${item.insta_name}&background=F54153&color=FFF`"
                ></v-img>
              </div>
              <span class="category">
                <strong>{{ item.insta_name }}</strong> <br>
                <v-chip
                  v-if="item.category"
                  x-small
                  color="teal"
                  text-color="white"
                >
                  {{ item.category }}
                </v-chip>
              </span>
            </a>
          </template>
          <template v-slot:[`item.insta_name`]="{ item }">
            <span>
              {{item.insta_name ? item.insta_name : '-'}} </span>
            <v-chip
              v-if="item.category"
              x-small
              color="teal"
              text-color="white"
            >
              {{ item.category }}
            </v-chip>
          </template>
          <template v-slot:[`item.category`]="{ item }">
            {{item.category ? item.category : '-'}}
          </template>
          <template v-slot:[`item.language`]="{ item }">
            {{item.language ? item.language : '-'}}
          </template>
          <template v-slot:[`item.gender`]="{ item }">
            {{item.gender ? item.gender : '-'}}
          </template>
          <template v-slot:[`item.total_media`]="{ item }">
            {{item.total_media | formatNumber}}
          </template>
          <template v-slot:[`item.followers`]="{ item }">
            {{item.followers | formatNumber}}
          </template>
          <template v-slot:[`item.add_time`]="{ item }">
            {{item.add_time | dateFormat}}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              v-if="item.status === 0"
              color="warning"
              small
              outlined
            >
              <v-icon
                small
                left
              >mdi-alert-circle-outline</v-icon> Pending
            </v-chip>
            <v-chip
              v-if="item.status === 1"
              color="success"
              small
              outlined
            >
              <v-icon
                small
                left
              >mdi-check-circle-outline</v-icon> Approved
            </v-chip>
            <v-chip
              v-if="item.status === 2"
              color="error"
              small
              outlined
            >
              <v-icon
                small
                left
              >mdi-close-octagon-outline</v-icon> Rejected
            </v-chip>
          </template>
          <template v-slot:[`item.reason`]="{ item }">
            <div
              v-if="item.status===0"
              style="color:#a55b27"
            >Admin Approval Pending</div>
            <div
              v-else
              style="color:#a55b27"
            >{{item.client_remark}}</div>
          </template>
          <template v-slot:[`item.id`]="{ item }">
            <div
              class="d-flex align-center justify-center"
              v-if="item.status === 0"
            >
              <!-- <v-icon
                  color="#F54153"
                  @click="assignedDelete(item.id)"
                >mdi-delete</v-icon> -->
              <v-btn
                icon
                dark
                color="secondary"
                size="28"
                @click="assignedDelete(item)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </div>
      <h5
        v-if="!loading && this.adminInfluencersList.length "
        class="ml-4 mt-6"
      >Influencers assigned by Admin</h5>
      <div v-if="platform === 1">
        <v-data-table
          :headers="headersyoutube2"
          v-if="this.adminInfluencersList.length"
          :items-per-page="this.adminInfluencersList.length"
          :disable-sort="true"
          :items="indexedItems3"
          mobile-breakpoint="0"
          hide-default-footer
        >
          <template v-slot:[`item.sr`]="{ item }">{{item.sr}}</template>
          <template v-slot:[`item.avatar`]="{ item }">
            <a
              :href="item.channel_link"
              target="_blank"
              class="d-flex align-center"
            >
              <div>
                <v-img
                  v-if="item.avatar"
                  :src="item.avatar"
                  class="img"
                ></v-img>
                <v-img
                  v-else
                  class="img"
                  :src="`https://ui-avatars.com/api/?name=${item.channel_name}&background=F54153&color=FFF`"
                ></v-img>
              </div>
              <span class="category">
                <strong>{{ item.channel_name }}</strong> <br>
                <v-chip
                  v-if="item.category"
                  x-small
                  color="teal"
                  text-color="white"
                >
                  {{ item.category }}
                </v-chip>
              </span>
            </a>
          </template>
          <template v-slot:[`item.yt_category`]="{ item }">
            {{item.yt_category ? catName(item.yt_category) : '-'}}
          </template>
          <template v-slot:[`item.language`]="{ item }">
            {{item.language ? item.language : '-'}}
          </template>
          <template v-slot:[`item.gender`]="{ item }">
            {{item.gender ? item.gender : '-'}}
          </template>
          <template v-slot:[`item.eng_rate`]="{ item }">
            {{ item.eng_rate ? item.eng_rate + '%' : '-'}}
          </template>
          <template v-slot:[`item.total_video`]="{ item }">
            {{item.total_video | formatNumber}}
          </template>
          <template v-slot:[`item.subscribers`]="{ item }">
            {{item.subscribers | formatNumber}}
          </template>
          <template v-slot:[`item.total_view`]="{ item }">
            {{item.total_view | formatNumber}}
          </template>
          <template v-slot:[`item.latest_avg_view`]="{ item }">
            {{item.latest_avg_view | formatNumber}}
          </template>
          <template v-slot:[`item.last_video_date`]="{ item }">
            {{item.last_video_date | dateFormat }}
          </template>
          <template v-slot:[`item.add_time`]="{ item }">
            {{item.add_time | dateFormat}}
          </template>
          <template v-slot:[`item.reason`]>
            <div style="color:#a55b27">Admin Assigned</div>
          </template>
        </v-data-table>
      </div>
      <div v-else>
        <v-data-table
          :headers="headersinsta2"
          :disable-sort="true"
          :items-per-page="this.adminInfluencersList.length"
          v-if="this.adminInfluencersList.length"
          :items="indexedItems3"
          mobile-breakpoint="0"
          hide-default-footer
        >
          <template v-slot:[`item.sr`]="{ item }">{{item.sr}}</template>
          <template v-slot:[`item.avatar`]="{ item }">
            <a
              :href="item.insta_link"
              target="_blank"
              class="d-flex align-center"
            >
              <div>
                <v-badge
                  v-if="item.is_verify"
                  color="success"
                  icon="mdi-check"
                  avatar
                  bordered
                  overlap
                >
                  <v-avatar size="45">
                    <v-img
                      class="img"
                      :src="`https://ui-avatars.com/api/?name=${item.insta_name}&background=F54153&color=FFF`"
                    ></v-img>
                  </v-avatar>
                </v-badge>
                <v-img
                  v-else
                  class="img"
                  :src="`https://ui-avatars.com/api/?name=${item.insta_name}&background=F54153&color=FFF`"
                ></v-img>
              </div>
              <span class="category">
                <strong>{{ item.insta_name }}</strong> <br>
                <v-chip
                  v-if="item.category"
                  x-small
                  color="teal"
                  text-color="white"
                >
                  {{ item.category }}
                </v-chip>
              </span>
            </a>
          </template>
          <template v-slot:[`item.insta_name`]="{ item }">
            <span>
              {{item.insta_name ? item.insta_name : '-'}} </span>
            <v-chip
              v-if="item.category"
              x-small
              color="teal"
              text-color="white"
            >
              {{ item.category }}
            </v-chip>
          </template>
          <template v-slot:[`item.category`]="{ item }">
            {{item.category ? item.category : '-'}}
          </template>
          <template v-slot:[`item.language`]="{ item }">
            {{item.language ? item.language : '-'}}
          </template>
          <template v-slot:[`item.gender`]="{ item }">
            {{item.gender ? item.gender : '-'}}
          </template>
          <template v-slot:[`item.total_media`]="{ item }">
            {{item.total_media | formatNumber}}
          </template>
          <template v-slot:[`item.followers`]="{ item }">
            {{item.followers | formatNumber}}
          </template>
          <template v-slot:[`item.reason`]>
            <div style="color:#a55b27">Admin Assigned</div>
          </template>
          <template v-slot:[`item.add_time`]="{ item }">
            {{item.add_time | dateFormat}}
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import { getInfluencerInfo, assignDelete } from '@/api/campaign'
import constantData from '@/utils/constant'
export default {
  data () {
    return {
      loading: false,
      platform: Number(this.$route.params.platform),
      adminInfluencersList: [],
      selectedInfluencers: [],
      influencersAppliedToCampaign: [],
      ytCategory: constantData.ytCategory,
      headersinsta: [
        { text: 'Sr.', value: 'sr', align: 'center' },
        { text: 'Channel', value: 'avatar', align: 'center' },
        { text: 'Language', value: 'language', align: 'center' },
        { text: 'Gender', value: 'gender', align: 'center' },
        { text: 'Followers', value: 'followers', align: 'center' },
        { text: 'Total Media', value: 'total_media', align: 'center' },
        { text: 'Add Time', value: 'add_time', align: 'center' },
        { text: 'Admin Approval', value: 'status', align: 'center' },
        { text: 'Reason', value: 'reason', align: 'center' },
        { text: '', value: 'id', align: 'center' }
      ],
      headersinsta2: [
        { text: 'Sr.', value: 'sr', align: 'center' },
        { text: 'Channel', value: 'avatar', align: 'center' },
        { text: 'Language', value: 'language', align: 'center' },
        { text: 'Gender', value: 'gender', align: 'center' },
        { text: 'Followers', value: 'followers', align: 'center' },
        { text: 'Total Media', value: 'total_media', align: 'center' },
        { text: 'Add Time', value: 'add_time', align: 'center' },
        { text: 'Reason', value: 'reason', align: 'center' }
      ],
      headersyoutube: [
        { text: 'Sr.', value: 'sr', align: 'center' },
        { text: 'Channel', value: 'avatar', align: 'center' },
        { text: 'Language', value: 'language', align: 'center' },
        { text: 'Gender', value: 'gender', align: 'center' },
        { text: 'Subscribers', value: 'subscribers', align: 'center' },
        { text: 'Total View', value: 'total_view', align: 'center' },
        { text: 'Total Post', value: 'total_video', align: 'center' },
        { text: 'Avg View', value: 'latest_avg_view', align: 'center' },
        { text: 'Engagement Rate', value: 'eng_rate', align: 'center' },
        { text: 'Last Video Publish Date', value: 'last_video_date', align: 'center' },
        { text: 'Add Time', value: 'add_time', align: 'center' },
        { text: 'Admin Approval', value: 'status', align: 'center' },
        { text: 'Reason', value: 'reason', align: 'center' },
        { text: '', value: 'id', align: 'center' }
      ],
      headersyoutube2: [
        { text: 'Sr.', value: 'sr', align: 'center' },
        { text: 'Channel', value: 'avatar', align: 'center' },
        { text: 'Language', value: 'language', align: 'center' },
        { text: 'Gender', value: 'gender', align: 'center' },
        { text: 'Subscribers', value: 'subscribers', align: 'center' },
        { text: 'Total View', value: 'total_view', align: 'center' },
        { text: 'Total Post', value: 'total_video', align: 'center' },
        { text: 'Avg View', value: 'latest_avg_view', align: 'center' },
        { text: 'Engagement Rate', value: 'eng_rate', align: 'center', width: 60 },
        { text: 'Last Video Publish Date', value: 'last_video_date', align: 'center', width: 100 },
        { text: 'Add Time', value: 'add_time', align: 'center' },
        { text: 'Reason', value: 'reason', align: 'center' }
      ]
    }
  },
  computed: {
    indexedItems () {
      return this.selectedInfluencers.map((item, index) => ({
        sr: index + 1,
        ...item
      }))
    },
    indexedItems2 () {
      return this.influencersAppliedToCampaign.map((item, index) => ({
        sr: index + 1,
        ...item
      }))
    },
    indexedItems3 () {
      return this.adminInfluencersList.map((item, index) => ({
        sr: index + 1,
        ...item
      }))
    }
  },
  mounted () {
    this.getInfluencerInfoDetails()
  },
  methods: {
    async getInfluencerInfoDetails () {
      this.loading = true
      const data = await getInfluencerInfo(this.$route.params.campaignId, this.$route.params.platform)
      this.selectedInfluencers = data.selectedInfluencers
      this.adminInfluencersList = data.adminInfluencers
      this.influencersAppliedToCampaign = data.influencersAppliedToCampaign
      this.loading = false
    },
    async assignedDelete (item) {
      this.loading = true
      const data = await assignDelete({ id: item.id })
      this.loading = false
      if (data.success) {
        this.$snackbar({
          message: data.message
        })
        this.getInfluencerInfoDetails()
      } else {
        this.$snackbar({
          message: data.message,
          color: 'error',
          timeout: 5000
        })
      }
    },
    catName (dataValue) {
      const newvalue = dataValue.split(',')
      var newcat = []
      for (let i = 0; i < newvalue.length; i++) {
        var cat = this.ytCategory.filter(function (obj) {
          return obj.value === newvalue[i]
        }).map(function (obj) {
          return obj.label
        })
        newcat = newcat.concat(cat)
      }
      if (!newcat.length) {
        return '-'
      } else {
        return newcat.toString()
      }
    }
  }
}
</script>

<style scoped>
  h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    color: #6b6b6b;
  }
  p {
    font-weight: 500;
    font-size: 14px;
    color: #333333;
  }
  strong {
    font-weight: 600;
  }
  .grey-bg {
    background: rgba(217, 217, 217, 0.17);
  }
  .v-data-table >>> .v-data-table__wrapper > table {
    border-spacing: 0 12px !important;
  }
  .theme--light.v-data-table >>> .v-data-table__wrapper table th {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #333333;
    border: none !important;
    padding: 5px 6px;
  }
  .v-data-table >>> .v-data-table__wrapper > table > tbody > tr > td {
    font-weight: normal;
    font-size: 14px;
    line-height: normal;
    color: #333333;
    border: none !important;
    padding: 5px 6px;
  }
  .v-data-table >>> .v-data-table__wrapper > table > tbody > tr {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.09);
    border-radius: 13px;
  }
  .v-data-table >>> tbody tr.v-data-table__selected {
    background: #e9ecfa;
  }
  .v-image {
    border-radius: 11px;
    box-shadow: -1px 2px 7px rgba(0, 0, 0, 0.11);
    margin: 0 auto;
  }
  .v-image >>> .v-image__image--preload {
    filter: none;
  }
  .img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
  }
  .category {
    display: inline-block;
    text-align: start;
    margin-left: 10px;
  }
</style>
