<template>
  <div class="pa-1">

    <v-progress-linear
      indeterminate
      color="primary"
      v-if="loading"
    ></v-progress-linear>

    <div v-if="posts.length > 0 && !loading">
      <div class="d-flex stats">
        <v-card class="mr-5 pa-2">
          <v-card-text>
            <div class="d-flex align-center">
              <div>
                <p>Total Views</p>
                <h3 v-if="stats.totalViews">{{ stats.totalViews | formatNumber }}</h3>
                <h3 v-else>0</h3>
              </div>
              <v-icon
                large
                color="primary"
                class="ml-10"
              >mdi-eye</v-icon>
            </div>
          </v-card-text>
        </v-card>

        <v-card class="mr-5 pa-2">
          <v-card-text>
            <div class="d-flex align-center">
              <div>
                <p>Engagement Rate</p>
                <h3>{{ stats.engageRate }}%</h3>
              </div>
              <v-icon
                large
                color="primary"
                class="ml-10"
              >mdi-chart-line</v-icon>
            </div>
          </v-card-text>
        </v-card>

        <v-card class="mr-5 pa-2">
          <v-card-text>
            <div class="d-flex align-center">
              <div>
                <p>
                  {{platform === 1 ? 'Cost Per View' : ' Cost Per Like'}}
                </p>
                <h3>{{ stats.cpv }}</h3>
              </div>
              <v-icon
                large
                color="primary"
                class="ml-10"
              >mdi-cash</v-icon>
            </div>
          </v-card-text>
        </v-card>
      </div>

      <h4 class="mt-8">CAMPAIGN POSTS</h4>

      <v-card class="mt-2">
        <v-card-text>
          <v-simple-table>
            <thead>
              <tr>
                <th width="30">Sr.</th>
                <th>Channel Name</th>
                <th>Post Views</th>
                <th>Post Likes</th>
                <th>Post Engage Rate</th>
                <th>Price</th>
                <th>Cost Per View</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in posts"
                :key="item.name"
              >
                <td>{{ index + 1 }}</td>
                <td>
                  <a
                    :href="item.post_link"
                    target="_blank"
                  >{{ item.channel_name }}</a>
                </td>
                <td>
                  <span v-if="platform === 1">{{ item.view_count | formatNumber }}</span>
                  <span v-if="platform === 2">{{ item.video_view_count | formatNumber }}</span>
                </td>
                <td>
                  {{ item.like_count | formatNumber }}
                </td>
                <td>
                  <span v-if="platform === 1">{{ item.video_eng_rate ? item.video_eng_rate : '0' }}%</span>
                  <span v-if="platform === 2">{{ instaPostEngRate(item) }}%</span>
                </td>
                <td>
                  {{ item.client_price | formatNumber }}
                </td>
                <td>
                  <span v-if="platform === 1">{{ item.client_price && item.view_count ? ((item.client_price/item.view_count)).toFixed(2) : '0' }}</span>
                  <span v-if="platform === 2">{{ item.client_price && item.like_count ? (((item.tds + item.client_price)/item.like_count)).toFixed(2) : '0' }}</span>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </div>

    <div
      class="text-center ma-8"
      v-if="!loading && posts.length <= 0"
    >
      <img
        src="@/assets/images/no-campaign.png"
        width="150"
      />
      <h3>No Data Found</h3>
    </div>

  </div>
</template>

<script>
import { getStats } from '@/api/campaign'
export default {
  data () {
    return {
      loading: false,
      campaignId: this.$route.params.campaignId,
      platform: null,
      stats: {
        totalViews: 0,
        likeCount: 0,
        engageRate: 0,
        cpv: 0
      },
      posts: []
    }
  },
  mounted () {
    this.fetchStatsData()
  },
  methods: {
    async fetchStatsData () {
      this.loading = true
      const response = await getStats(this.campaignId)
      this.platform = response.platform

      if (response.success && response.posts.length) {
        this.posts = response.posts
        const totalCost = response.costData.clientprice || 0

        // stats
        if (response.platform === 1) {
          this.stats.totalViews = response.posts.map(element => parseInt(element.view_count ? element.view_count : 0)).reduce((a, b) => a + b, 0)
          this.stats.likeCount = response.posts.map(element => parseInt(element.like_count ? element.like_count : 0)).reduce((a, b) => a + b, 0)

          const dislikeCount = response.posts.map(element => element.dislike_count ? element.dislike_count : 0).reduce((a, b) => a + b, 0)
          const comment = response.posts.map(element => element.comment_count ? element.comment_count : 0).reduce((a, b) => a + b, 0)
          const viewCount = response.posts.map(element => element.view_count ? element.view_count : 0).reduce((a, b) => a + b, 0)

          this.stats.engageRate = ((((this.stats.likeCount || 0) + dislikeCount + comment) / viewCount) * 100) || 0
          this.stats.engageRate = this.stats.engageRate ? this.stats.engageRate.toFixed(2) : 0
          this.stats.cpv = (totalCost > 0 && viewCount > 0) ? (totalCost / viewCount).toFixed(2) : 0
        } else {
          this.stats.totalViews = response.posts.map(element => parseInt(element.video_view_count ? element.video_view_count : 0)).reduce((a, b) => a + b, 0)
          this.stats.likeCount = response.posts.map(element => parseInt(element.like_count ? element.like_count : 0)).reduce((a, b) => a + b, 0)

          const comment = response.posts.map(element => element.comment_count ? element.comment_count : 0).reduce((a, b) => a + b, 0)
          const viewCount = response.posts.map(element => element.video_view_count ? element.video_view_count : 0).reduce((a, b) => a + b, 0)

          this.stats.engageRate = ((((this.stats.likeCount || 0) + comment) / viewCount) * 100) || 0
          this.stats.engageRate = this.stats.engageRate ? this.stats.engageRate.toFixed(2) : 0
          this.stats.cpv = (totalCost > 0 && (viewCount || 0) > 0) ? (totalCost / viewCount).toFixed(2) : 0
        }
      }

      this.loading = false
    },

    instaPostEngRate (item) {
      const rate = ((((item.like_count || 0) + (item.comment_count || 0)) / (item.video_view_count || 0)) * 100).toFixed(2)
      return !isNaN(rate) ? rate : 0
    }
  }
}
</script>

<style scoped>
  .stats h3 {
    color: #333333;
    margin: 5px 0px 0px 0px;
  }
  /* p {
        margin: 0 0 2px;
      } */
  .stats p {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #6b6b6b;
    margin: 0;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #333333;
    border: none !important;
    padding: 16px 10px;
  }
</style>
